import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Fragment,
} from "react"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import Axios from "axios"
import Loader from "react-loader-spinner"
import Layout from "../../components/layout"
import debounce from "lodash.debounce"

const getData = (url, setData, cbLoading) => {
  Axios.get(url)
    .then((data) => {
      const responseData = data?.data?.data ?? []
      if (responseData) {
        setData(responseData)
      }
    })
    .catch((err) => {
      setData([])
      console.log(err)
    })
  cbLoading()
}

const debouncedFetchData = debounce((query, cb, cbLoading) => {
  getData(query, cb, cbLoading)
}, 500)

const Search = () => {
  const [data, setData] = useState([])
  const [text, setText] = useState("")
  const [loadMore, setLoadMore] = useState(20)
  const [loading, setLoading] = useState()
  // const navigate = useLocation()

  const closeSearch = (e) => {
    console.log(e)
    history && history.back()
  }

  const url = useMemo(() => {
    return `${process.env.GATSBY_SERVER_SEARCH}/?query=${text.toLowerCase()}`
  }, [text])

  useEffect(() => {
    if (text && text.length > 3) {
      debouncedFetchData(url, setData, () => {
        setLoading(false)
      })
    }
  }, [url, text])

  const onSearch = useCallback((event) => {
    setLoading(true)
    setText(event.target.value)
  }, [])

  const getFormat = useCallback((data) => {
    const title = data.title ? data.title : "Page Swim"
    const description = data.body
      ? data.body
      : data.description
      ? data.description
      : "Page swim"
    let itemURL = "/"
    if (data.slug) {
      itemURL = `${itemURL}${data.slug}`
    }
    let root = false
    for (const ancestor of data.ancestors) {
      if (ancestor.slug !== "swim-ontario-2019" && !root) {
        itemURL = `/${ancestor.slug}${itemURL}`
      } else {
        root = true
      }
    }

    return (
      <li className="line-result">
        <h2>{title}</h2>
        <p>{description}</p>
        <Link to={itemURL} className="read-more">
          Read more
          <img
            className="arrow-right arrow"
            src={`../images/arrow-right-2.png`}
            alt=""
          />
        </Link>
      </li>
    )
  }, [])

  const onClick = useCallback(() => {
    setLoadMore((prev) => prev + 20)
  }, [loadMore])

  const getLoadMore = useCallback(() => {
    const total = data ? data.length : 0
    if (total < 20) {
      return null
    }
    if (loadMore >= total) {
      return <></>
    }
    return (
      <button onClick={onClick} className="button-load-more">
        Load More
      </button>
    )
  }, [data, loadMore, onClick])

  return (
    <Fragment>
      <div id="myOverlay" className="overlay">
        <span
          className="closebtn"
          onClick={(e) => closeSearch(e)}
          onDoubleClick={(e) => closeSearch(e)}
          title="Close Overlay"
          role="button"
          aria-label="close"
          aria-hidden="true"
        >
          ×
        </span>
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xs-12 col-lg-12">
              <div className="overlay-content">
                <form action="">
                  <h1 style={{ textAlign: "left" }}>SEARCH</h1>
                  <input
                    type="text"
                    placeholder="Search.."
                    name="search"
                    onChange={onSearch}
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="separator">
            <div></div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-lg-12">
              <div className="box-count">
                {data && <p className="count">{data.length} result(s)</p>}
              </div>
              <div className="results">
                {loading && (
                  <Loader
                    type="Puff"
                    color="#00aad8"
                    height={100}
                    width={100}
                  />
                )}
                <ul>
                  {data &&
                    data?.map((item, index) => {
                      if (loadMore >= index) {
                        return getFormat(item)
                      }
                      return ""
                    })}
                </ul>
                <div className="text-center search-load-more">
                  {getLoadMore()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Search
